<template>
  <div>
    <div v-if="loader" class="d-flex justify-content-center mb-3">
      <b-button variant="flat-primary" disabled class="mr-1">
        <b-spinner small />
        Chargement des utilisateurs...
      </b-button>
    </div>
    <div v-else>
      <b-row>
        <b-col cols="12">
          <p>
            <b-button class="float-right" v-b-modal.modal-add variant="primary">Ajouter</b-button>
          </p>
        </b-col>
      </b-row>
      <p>&nbsp;</p>
      <b-row>
        <b-col cols="12">
          <b-table
            :items="users.users"
            :fields="fields"
            v-on:row-clicked="edit"
            class="clear"
          >
            <template #cell(action)="row">
              <b-button size="sm" class="mr-2"  v-on:click="edit_user(row.item)" variant="primary">
                <feather-icon icon="Edit2Icon" size="12" />
              </b-button>
              <b-button size="sm" class="mr-2" v-on:click="delete_user(row.item)" variant="primary">
                <feather-icon icon="XIcon" size="12" />
              </b-button>
            </template>
            <template #cell(aircall)="row">
                <feather-icon icon="CheckSquareIcon" size="12" v-if="row.item.aircall" />
                <feather-icon icon="XIcon" size="12" v-else />
            </template>
          </b-table>

        </b-col>
      </b-row>
      <b-modal
        id="modal-add"
        modal-class="modal-add"
        cancel-variant="outline-secondary"
        ok-title="Enregistrer"
        cancel-title="Annuler"
        centered
        :hide-footer="true"
        title="Ajouter un utilisateur"
      >
        <b-form>
          <b-form-group>
            <label for="email">Email:</label>
            <b-form-input
              id="email"
              type="email"
              v-model="newuser.email"
              placeholder="Email Address"
            />
          </b-form-group>
          <b-form-group>
            <label for="password">Nom</label>
            <b-form-input type="text" v-model="newuser.nom" placeholder="Nom" />
          </b-form-group>
          <b-form-group>
            <label for="password">Prénom</label>
            <b-form-input
              type="text"
              v-model="newuser.prenom"
              placeholder="prenom"
            />
          </b-form-group>
          <b-form-group>
            <label for="password">Equipe</label>
     
            <b-form-select
                v-model="newuser.team"
                :options="users.teams"
           
                value-field="id_team"
                text-field="name"
                disabled-field="notEnabled"
                ></b-form-select>
          </b-form-group>
          <b-form-group>
            <label for="password">Aircall</label>
            <b-form-select
                v-model="newuser.aircall"
                :options="users.aircall_users"
           
                value-field="id"
                text-field="name"
                disabled-field="notEnabled"
                ></b-form-select>
 
          </b-form-group>
        </b-form>
        <b-button block v-on:click="add_user" variant="primary">
          <span class="align-middle">Ajouter</span>
        </b-button>
      </b-modal>
      <b-modal
        id="modal-upd"
        ref="modal-upd"
        modal-class="modal-upd"
        cancel-variant="outline-secondary"
        ok-title="Enregistrer"
        cancel-title="Annuler"
        centered
        :hide-footer="true"
        title="Edition utilisateur"
      >
        <b-form>
          <b-form-group>
            <label for="email">Email:</label>
            <b-form-input
              id="email"
              type="email"
              v-model="edituser.data.email"
              placeholder="Email Address"
            />
          </b-form-group>
          <b-form-group>
            <label for="password">Nom</label>
            <b-form-input type="text" v-model="edituser.data.nom" placeholder="Nom" />
          </b-form-group>
          <b-form-group>
            <label for="password">Prénom</label>
            <b-form-input
              type="text"
              v-model="edituser.data.prenom"
              placeholder="prenom"
            />
          </b-form-group>
          <b-form-group v-if="edituser.data.id_team != null">
            <label for="password">Equipe</label>
            <b-form-select
                v-model="edituser.data.id_team"
                :options="users.teams"
                value-field="id_team"
                text-field="name"
                disabled-field="notEnabled"
                ></b-form-select>
            
          </b-form-group>
          <b-form-group  v-if="edituser.data.aircall != null">
            <label for="password">Aircall</label>
            <b-form-select
                v-model="edituser.data.aircall"
                :options="users.aircall_users"
                value-field="id"
                text-field="name"
                disabled-field="notEnabled"
                ></b-form-select>
            
          </b-form-group>
        </b-form>
        <b-button block v-on:click="upd_user" variant="primary">
          <span class="align-middle">Enregistrer</span>
        </b-button>
      </b-modal>
    </div>
  </div>
</template>

<script>
import { $themeConfig } from "@themeConfig";
import vSelect from "vue-select";
import {
  BAvatar,
  BBadge,
  BPagination,
  BForm,
  BFormGroup,
  BFormInput,
  BDropdown,
  BDropdownItem,
  BTable,
  BButton,
  BRow,
  BCol,
  BModal,
  VBModal,
  BSpinner,
  BFormSelect
} from "bootstrap-vue";
import store from "@/store/index";

export default {
  components: {
    BTable,
    BButton,
    BAvatar,
    BBadge,
    BForm,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    BRow,
    BCol,
    BModal,
    vSelect,
    BSpinner,
    BFormSelect
  },
  directives: {
    "b-modal": VBModal,
  },
  data() {
    return {
      loader: true,
      newuser: {
        team: null,
        email: "",
        prenom: "",
        nom: "",
        aircall: null,
      },
      edituser: {
          data: {
            team: null,
            email: "",
            prenom: "",
            nom: "",
            aircall: null
          }
      },
      pageLength: 3,
      dir: false,
      fields: ["email", "nom", "prenom", "teamname", "aircall"],
      fields: [
        {
          key: "nom",
          label: "Nom",
          sortable: true,
        },
        {
          key: "prenom",
          label: "Prénom",
          sortable: false,
        },
        {
          key: "email",
          label: "Email",
          sortable: true,
        },
        {
          key: "equipe",
          label: "Equipe",
          sortable: true,
        },
        {
          key: "aircall",
          label: "Aircall",
        },
        {
          key: "action",
          label: "Action",
        },
        
      ],
      columns: [
        {
          label: "Prénom",
          field: "prenom",
        },
        {
          label: "Nom",
          field: "nom",
        },
        {
          label: "Email",
          field: "email",
        },
        {
          label: "Equipe",
          field: "teamname",
        },
        {
          label: "Action",
          field: "action",
        },
      ],
      users: {
        users: [],
        teams: [],
        aircall_users: [],
      },
      searchTerm: "",
    };
  },

  created() {
    this.$http.post($themeConfig.app.restEndPoint + "/users").then((res) => {
      this.$set(this.users, "users", res.data.users);
      this.$set(this.users, "teams", res.data.teams);
      this.$set(this.users, "aircall_users", res.data.aircall_users);
      this.users.teams = res.data.teams
      this.users.aircall_users = res.data.aircall_users
      setTimeout(() => {
        this.loader = false;
      }, 1000);
    });
  },

  methods: {
    edit(item) {
      console.log(item);
    },
    add_user() {
        this.loader = true;
        this.$http.post($themeConfig.app.restEndPoint + "/user/add", this.newuser).then((res) => {
            this.$set(this.users, "users", res.data.users);
            this.loader = false;
            this.newuser = {
                team: null,
                email: "",
                prenom: "",
                nom: "",
                aircall: null,
            }
        });
    },
    upd_user() {
        this.loader = true;
        this.$http.post($themeConfig.app.restEndPoint + "/user/upd", this.edituser.data).then((res) => {
            this.$set(this.users, "users", res.data.users);
            this.loader = false;
            this.edituser = {
                data: {
                    team: null,
                    email: "",
                    prenom: "",
                    nom: "",
                    aircall: null
                }
            }
        });
    },
    edit_user(user) {
        console.log(user)
        this.$set(this.edituser, "data", user)
        /*
        for (var i=0; i < this.users.aircall_users.length; i++) {
            if (this.users.aircall_users[i].id = this.edituser.data.aircall) {
                //this.edituser.id_aircall = this.users.aircall_users[i].id
                this.$set(this.edituser.data, "aircall", this.users.aircall_users[i])
                break;
            }
        }
        
        for (var i=0; i < this.users.teams.length; i++) {
            if (this.users.teams[i].id_team = this.edituser.data.id_team) {
                this.$set(this.edituser.data, "team", this.users.teams[i].id_team)
                break;
            }
        }
        */
        this.$refs["modal-upd"].show();
    },
    delete_user(user) {
        console.log(user)
        this.loader = true;
        this.$http.post($themeConfig.app.restEndPoint + "/user/delete", {"id_user": user.id_user}).then((res) => {
            this.$set(this.users, "users", res.data.users);
            this.loader = false;
        });
    }
  },
};
</script>
 